<template>
  <Container :visible="visible" class="select-map-trace" @confirm="onConfirm">
    <Row label="属地业务员" @click="isShowMembers=true">
      <span class="label">{{salesmanName || '请选择'}}</span>
    </Row>
    <Row label="日期">
      <DatePicker v-model="date"/>
    </Row>
    <div class="fixed-container" v-if="isShowMembers">
      <SalesmanSelect 
        selectType="salesman" 
        v-model="salesmanList" 
        @confirm="isShowMembers=false"
      />
    </div>
  </Container>
</template>

<script>
import Row from "../row";
import Container from "../container";
import DatePicker from '../datePicker';
import SalesmanSelect from '@/views/select/blockSelect';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Row,
    Container,
    DatePicker,
    SalesmanSelect,
  },
  computed: {
    salesmanName() {
      return this.salesmanList[0] && this.salesmanList[0].nickName || '';
    },
    salesmanId() {
      return this.salesmanList[0] && this.salesmanList[0].userId || '';
    },
  },
  data() {
    return {
      date: new Date(),
      salesmanList: [],
      isShowMembers: false,
    };
  },
  methods: {
    onConfirm() {
      this.$emit('confirm', {
        lastTime: this.date,
        businessMasterId: this.salesmanId,
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.select-map-trace {
  .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #cecece;
  }
}
</style>
