<template>
  <div class="map-trace">
    <Header :icons="[{icon: FilterIcon, event: 'filter'}]" @filter="isShowFilter=true" />
    <!-- 地图 -->
    <div class="map-container" id="mapContainer"></div>

    <MapFilter :visible="isShowFilter" @confirm="onFilterConfirm" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { fetchTrace } from '@/api/map';
import { initMap, playMapTrace } from '@/utils/map';
import Header from '@/components/header/headerWithIcon/index.vue';
import FilterIcon from '@/assets/images/common/filter-white.png';
import MapFilter from '@/components/select/map/trace';

export default {
  components: {
    MapFilter,
    Header,
  },
  data() { 
    return {
      list: [],
      FilterIcon,
      tabIndex: 0,
      isShowFilter: false,
    }
  },
  async mounted() {
    const res = await initMap('mapContainer');
    this.map = res.map;
    if (this.$route.params && this.$route.params.user) {
      this.onFilterConfirm({
        businessMasterId: this.$route.params.user.userId,
      });
    }
  },
  methods: {
   async onFilterConfirm(val) {
      this.isShowFilter = false;
      const params = {
        page: {
          pageNo: 1,
          pageSize: 5000,
          orderBy: "distance"
        },
        businessMasterId: val.businessMasterId,
        lastTime: dayjs(val.lastTime || new Date()).format("YYYY-MM-DD"),
      }
      const res = await fetchTrace(params);
      const path = res.data.map(d => {
        return {
          lng: d.longitude,
          lat: d.latitude
        }
      });
      if (path.length && path.length > 1) {
        playMapTrace(this.map, path);
      } else {
        this.$toast('该业务员暂无轨迹');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.map-trace {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .map-container {
    width: 100%;
    flex: 1;
  }
}
</style>
